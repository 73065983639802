import React, { Component } from 'react'

export default class Cards extends Component {
    render() {
        return (
            <center>
                <div className="cards mt-3">
                    <center><img src={this.props.imgLink} width={this.props.width} alt={this.props.altName} /></center>
                    <div className="card-body">
                        <h5 className="card-title subtitle">{this.props.cardTitle}</h5>
                        <p className="card-text subinfo">{this.props.cardContent}</p>
                    </div>
                </div>
            </center>
        )
    }
}
