import React, { Component } from 'react'
export default class Logo extends Component {
    render() {
        let subLink = {
            color: "red",
        }
        return (
            <div>
                <h1 className="logo-snow text-center"><i>SNOW<span style={subLink} className="logo-link">LINK</span></i></h1>
            </div>
        )
    }
}
