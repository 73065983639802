import { Field } from 'formik'
import React, { Component } from 'react'

export class InputField extends Component {
    render() {
        return (
            <div className="form-group">
                <Field type={this.props.type} className={`form-control ${this.props.validate_class}`} placeholder={this.props.placeholder} {...this.props} />
            </div>
        )
    }
}

export class PButton extends Component {
    render() {
        return (
            <button type={this.props.type} className="w3-button w3-red  w3-hover-red m-1" {...this.props}>{this.props.buttonName}</button>
        )
    }
}