import React, { useEffect, useState } from 'react';
import Cards from './components/cards/cards';
import Login from './components/login-card/login';
import logo from './logo.svg'
function App() {
  let handleRedirect = (e) => {
    let modalName = e.target.alt
    if (modalName === 'facebook') {
      let path = `https://www.facebook.com/SnowmanLogistics`
      window.open(path, '_blank')
    } else if (modalName === 'linkedIn') {
      let path = `https://www.linkedin.com/company/snowmanlogisticslimited/`
      window.open(path, '_blank')
    } else {
      let path = ` https://wa.me/+918792507080`
      window.open(path, '_blank')
    }
  }
  let mediaQuery = {
    desktop: 1200,
    tablet: 768,
    phone: 576,
  };
  const [windowWidth, setwindowWidth] = useState(null)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setwindowWidth(document.body.clientWidth)
    })
  })
  return (
    <div style={{
      width: windowWidth > mediaQuery.phone
        ? '50%'
        : '100%',
    }}>
      <div className="loginScreen">
        <div className="d-flex flex-row-reverse pr-2 mr-2">
          <div>
            <Login />
          </div>
        </div>
        <div className="d-flex justify-content-end p-2 mt-5">
          <img className="logo-elixia mt-2" src={logo} width="120px" alt="Elixia Tech" />
        </div>
      </div>
      <div className="row">
        <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-lx-3">
          <Cards imgLink="../assets/img/1.svg" width="15%" altName="transparency"
            cardTitle="Transparency"
            cardContent="Provides an explicitly picture for upcoming. current and past Loads along with pricing and billing."
          />
        </div>
        <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-lx-3">
          <Cards imgLink="../assets/img/2.svg" width="15%" altName="freight"
            cardTitle="Freight Ready to be Drawn"
            cardContent="Alerts you with upcoming loads. Which keeps your business rolling quickly and efficiently."
          />
        </div>
        <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-lx-3">
          <Cards imgLink="../assets/img/3.svg" width="15%" altName="one-stop"
            cardTitle="One-stop Solution"
            cardContent="Park your worries for sales and focus on vehicle management"
          />
        </div>
        <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-lx-3">
          <Cards imgLink="../assets/img/4.svg" width="22%" altName="single-platform"
            cardTitle="Single Platform For All"
            cardContent="Solitary Platform starting from On-Boarding, Bidding, Shipment till Billing."
          />
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-center">
        <img style={{ cursor: 'pointer', width: '50px', padding: '5px' }} onClick={handleRedirect} alt="facebook"
          src="../assets/img/fb.svg" />
        <img style={{ cursor: 'pointer', width: '50px', padding: '5px' }} onClick={handleRedirect} className="mt-1" alt="linkedIn" src="../assets/img/linkedin.svg" />
        <img style={{ cursor: 'pointer', width: '50px', padding: '5px' }} onClick={handleRedirect} alt="whatsapp" src="../assets/img/whatsapp.svg" />
      </div>
      <div className="">
        <center>E-mail ID: <a href="mailto:snowlink@snowman.in">snowlink@snowman.in</a> </center>
      </div>
    </div>
  );
}

export default App;
