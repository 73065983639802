import { Form, Formik } from 'formik'
import React, { Component } from 'react'
import { InputField, PButton } from '../common/input-field'
import Logo from '../common/logo'
import * as yup from 'yup';
import Axios from 'axios';
import cogoToast from 'cogo-toast';

export default class Login extends Component {

    RedirectBtn = () => {
        let TOB_URL = `${process.env.REACT_APP_REDIRECT_TO_TOB}`
        cogoToast.loading('Please wait...').then(() => {
            window.location.href = TOB_URL
        })
    }
    render() {
        const validationSchema = yup.object({
            username: yup.string().required('Required'),
            password: yup.string().required('Required')
        })
        return (
            <div className="container login-container w-100">
                <div className="p-2">
                    <Logo />
                    <Formik
                        initialValues={{
                            username: '',
                            password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            let toast = cogoToast.loading('Please wait...');
                            setSubmitting(true);
                            Axios.post(`${process.env.REACT_APP_BASE_URL}/sso/login`, values, { headers: { 'content-type': 'application/json' } })
                                .then((response) => {
                                    if (response.status === 200) {
                                        if (response.data.resultSet.token) {
                                            let userKey = response.data.resultSet.userKey
                                            let speedToken = response.data.resultSet.speedToken
                                            let ta_url = process.env.REACT_APP_REDIRECT_TO_TA
                                            toast.then(() => {
                                                let TA_URL = `${ta_url}?token=${speedToken}&userKey=${userKey}`
                                                window.location.href = TA_URL
                                            })

                                        }
                                    }
                                })
                                .catch((err) => {
                                    // console.log(err.response.data.message)
                                    toast.then(() => {
                                        cogoToast.error(
                                            <div>
                                                <b>{err.response ? err.response.data.message : 'Something went wrong'}</b>
                                                <div>{err.response ? err.response.data.reason : 'Please try again'}</div>
                                            </div>,
                                        );
                                        setSubmitting(false);
                                    })
                                })
                        }}
                    >
                        {({ isSubmitting, errors, touched }) => (
                            <Form className="needs-validation">
                                <InputField validate_class={errors.username && touched.username ? 'is-invalid' : ''} type="text" name="username" placeholder="username" autoComplete="on" />
                                <InputField validate_class={errors.password && touched.password ? 'is-invalid' : ''} type="password" name="password" placeholder="password" />
                                <PButton type="submit" onClick={this.handleAction} data-key="to_ta" disabled={isSubmitting} buttonName="Sign In" autoComplete="on" />
                                <span className="float-md-right float-sm-left">
                                    <PButton type="button" onClick={this.RedirectBtn} buttonName="Partner with us" />
                                </span>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        )
    }
}
